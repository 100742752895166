<template>
  <div>
    <p v-if="error" class="error" v-html="error"></p>
    <h1>Assign</h1>
    <p v-if="!result">
      Please scan the QR Code in order to assign the container to this route's
      customer
    </p>

    <p v-if="result" class="alert success" v-html="msg"></p>
    <v-btn color="primary" @click="reset" class="mx-auto" v-if="result">
      Assign More
    </v-btn>
    &nbsp;
    <v-btn
      color="red"
      @click="extras"
      class="mx-auto white--text"
      v-if="result"
    >
      Done
    </v-btn>
    <qrcode-stream
      :torch="true"
      @decode="onDecode"
      :track="paintOutline"
      @init="onInit"
      v-if="!result"
    />
    <div class="text-center mt-5" v-if="!result">
      <v-btn color="red" @click="extras" class="mx-auto white--text">
        Cancel
      </v-btn>
    </div>
    <div class="text-center mt-5" v-if="!result">
      <div>
        <v-checkbox v-model="first_time_drop_off" label="Drop-Off"></v-checkbox>
        <v-text-field
          label="Manual QR input"
          v-model="manual_value"
        ></v-text-field>
        <template v-if="muestra_location">
          <div>
            <v-select
              :items="paper_locations"
              label="Location"
              v-model="paper_location"
            ></v-select>
            <template v-if="paper_location == 'Other'">
              <v-text-field
                label="Other Location"
                v-model="other_location"
              ></v-text-field>
            </template>
          </div>
        </template>
        <v-btn
          color="primary"
          @click="manual_capture"
          class="mb-5"
          :disabled="
            (muestra_location && paper_location == '') ||
            (muestra_location &&
              paper_location == 'Other' &&
              other_location == '')
          "
        >
          Send
        </v-btn>
      </div>
    </div>
    <div v-if="assigned.length > 0" class="mt-5">
      <h3>CONTAINERS ASSIGNED</h3>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">QR ID</th>
              <th class="text-left">Container</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in assigned" :key="index">
              <td>{{ item.qrcontainer_id }}</td>
              <td>
                <span v-if="item.first_time_drop_off">(Drop-Off) - </span
                >{{ item.container
                }}<span v-if="item.paper_location">
                  - {{ item.paper_location }}</span
                >
              </td>
              <td>
                <v-btn
                  color="red"
                  @click="deleteContainer(index)"
                  elevation="0"
                  fab
                  small
                  dark
                  class="mx-auto white--text"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },

  data() {
    return {
      result: "",
      error: "",
      msg: "",
      assigned: [],
      manual_value: "",
      muestra_location: false,
      paper_location: "",
      other_location: "",
      paper_locations: [
        "Floor 1",
        "Floor 2",
        "Floor 3",
        "Coffee Room",
        "HR Office",
        "Front Desk",
        "Hallway",
        "Breakroom",
        "Supply Room",
        "Other",
      ],
      first_time_drop_off: false,
    };
  },

  methods: {
    extras() {
      this.$router.push({
        name: "Routes",
        params: { route_back: this.$route.params.route_back },
      });
    },
    reset() {
      this.result = "";
      this.muestra_location = false;
      this.paper_location = "";
      this.other_location = "";
    },
    deleteContainer(id) {
      var este = this;
      this.$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "unassignContainer";
          var qr_container = this.assigned[id].qrcontainer_id;
          var datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            location: este.location,
            customer_id: este.$store.getters.customer_id,
            rs: este.$store.getters.route_id,
            qr_container: qr_container,
          };
          este.assigned.splice(id, 1);
          este.$http
            .post(este.$api + laurl, datos)
            .then(function (response) {
              este.assigned.splice(id, 1);
              este.result = true;
              este.msg = response.data.msg;
              este.assigned = response.data.assigned;
            })
            .catch(function (err) {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "There was an error. Please try again.",
              };
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
            });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    manual_capture() {
      var este = this;
      este
        .$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "assignContainer";
          var datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            customer_id: este.$store.getters.customer_id,
            location: este.location,
            rs: este.$store.getters.route_id,
            qr_container: "0-0-" + este.manual_value,
          };
          if (este.muestra_location) {
            datos.paper_location = este.paper_location;
            if (este.paper_location == "Other") {
              datos.paper_location = este.other_location;
            }
          }
          if (este.first_time_drop_off) {
            datos.first_time_drop_off = 1;
          }
          este.$http.post(este.$api + laurl, datos).then(function (response) {
            if (response.status == 200 && response.data.status == "ok") {
              este.result = este.manual_value;
              este.msg = response.data.msg;
              este.assigned = response.data.assigned;
              este.manual_value = "";
            } else if (
              response.status == 200 &&
              response.data.status == "muestra_location"
            ) {
              este.muestra_location = true;
              este.error = "";
              // este.assigned = response.data.assigned;
            } else {
              este.error = response.data.msg;
              este.assigned = response.data.assigned;
            }
          });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    onDecode(result) {
      var este = this;
      este
        .$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "assignContainer";
          var datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            customer_id: este.$store.getters.customer_id,
            location: este.location,
            rs: este.$store.getters.route_id,
            qr_container: result,
          };
          if (este.muestra_location) {
            datos.paper_location = este.paper_location;
            if (este.paper_location == "Other") {
              datos.paper_location = este.other_location;
            }
          }
          if (este.first_time_drop_off) {
            datos.first_time_drop_off = 1;
          }
          este.$http.post(este.$api + laurl, datos).then(function (response) {
            if (response.status == 200 && response.data.status == "ok") {
              este.result = result;
              este.msg = response.data.msg;
              este.assigned = response.data.assigned;
              este.muestra_location = false;
            } else if (
              response.status == 200 &&
              response.data.status == "muestra_location"
            ) {
              este.muestra_location = true;
              este.error = "";
              este.manual_value = result.split("-")[2];
              // este.assigned = response.data.assigned;
            } else {
              este.error = response.data.msg;
              este.assigned = response.data.assigned;
              este.muestra_location = false;
            }
          });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    getAssigned() {
      var este = this;
      var laurl = "getAssigned";
      var datos = {
        driver_id: este.$store.getters.driver_id,
        token: este.$store.getters.token,
        customer_id: este.$store.getters.customer_id,
        rs: este.$store.getters.route_id,
      };
      este.$http
        .post(este.$api + laurl, datos)
        .then(function (response) {
          este.assigned = response.data.assigned;
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again.",
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
  },
  mounted() {
    this.getAssigned();
  },
};
</script>

<style scoped>
.error {
  color: white;
  padding: 10px;
  text-align: center;
}

.success {
  font-weight: bold;
  color: white;
  padding: 10px;
  text-align: center;
}
</style>
